@import-normalize;

@import url('//fonts.cdnfonts.com/css/montserrat');

@import 'variables';

html {
    background-color: var(--dark);
}

body, html {
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    
    // font-family: 'Montserrat', sans-serif;
    // font-size: 16pt;
    // font-weight: 500;
}

button {
    //font-family: 'Montserrat', sans-serif;
}

a {
    color: var(--card-text-color);
    display: inline-block;
    &:hover {
        color: var(--accent-color);
        cursor: pointer;
    }
}