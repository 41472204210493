.eval-input {
    &__label {
        font-size: 1.2rem;
        font-weight: bold;
        margin: .5rem;
    }
    &__description {
        margin: .5rem;
    }

    &__buttons {
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    &__button {
        font-size: 2rem;
        border-radius: 1rem;
        width: 3rem;
        height: 3rem;
        text-align: center;
        padding: 0;

        cursor: pointer;

        --eval-input-border-width: .2rem;

        background-color: var(--light-gray);
        color: var(--dark-gray);
        border: .1rem solid var(--light-gray);

        &:hover {
            color: var(--accent-color);
            border: var(--eval-input-border-width) solid  var(--accent-color);
        }
        &:active {
            color: var(--white);
            border: var(--eval-input-border-width) solid  var(--white);
        }


        &--selected, &--selected:hover, &--selected:active {
            background-color: var(--accent-color);
            color: var(--white);
            border: var(--eval-input-border-width) solid  var(--white);
        }
        &--na {
            font-size: 1rem;
        }
    }

    &__notes {
        display: block;
        margin: 1rem;
        width: 100%;
        label {
            display: block;
        }
        textarea {
            display: block;
            width: 100%;
            height: 5rem;
            margin: .5rem 0;
            font-size: 1rem;
            border-radius: .5rem;
        }
    }
}