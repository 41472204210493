
.instructor-dashboard {

    padding: 2rem;

    &__title {
        color: var(--white);
        text-align: center;
    }

    &__subtitle {
        color: var(--white);
    } 
    
    &__back {
        color: var(--white);
    }

    &__search {
        padding: 0.5rem 1rem;
        width: 75%;
        max-width: 100%;
        border-radius: 0.5rem;
        color: var(--background-color);
        background-color: var(--light-gray);
        font-weight: bold;
    }

    &__slide {
        display: flex;
        overflow-x: auto;
        padding: 1rem 3rem 1rem 1rem;

        .student-card {
            margin: 0 1rem;
            a {
                text-decoration: none;
                &:hover {
                    color: var(--white);
                    text-decoration: underline;
                }
            }
        }
    }
}