.student-progress-chart {
    width: 100%;
    height: 75%;

    .recharts-cartesian-grid-horizontal {
        line {
            stroke: var(--chart-line);
        }
    }

    .recharts-cartesian-axis {
        line {
            display: none;
        }
    }

}