
.profile {

    padding: 2rem;

    &__title {
        color: var(--white);
        text-align: center;
    }

    &__subtitle {
        text-align: center;
        color: var(--white);
    } 

    &__car-form {
        text-align: left;
    }

    &__field {
        position: relative; 
        width: 100%;
        margin: 1rem 0;
        

        input.text {
            padding: 1rem 3rem 1rem 1rem;
            display: block;
            border-radius: .5rem;
            font-size: 1.25rem;
            width: calc(100% - 4rem);
            margin: 0;
        }

        label.text {
            position: absolute;
            top: .25rem;
            left: 1rem;
            font-size: .65rem;
            background-color: var(--white);
        }

        &-icon {
            position: absolute;
            right: 1rem;
            height: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
        }

        &--communication {
            position: relative;
            width: calc(100% - 4rem);
            margin: 1rem 0;
            padding: 1rem 3rem 1rem 1rem;
            display: block;
            border-radius: 0.5rem;
            font-size: 1.25rem;
            background-color: var(--white);

            
        }


    }
    &__radio-group {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__radio {
        margin: .25rem;
        width: 25%;
        min-width: 4rem;
        text-align: center;
        label {
            margin-left: .5rem;
        }
    }



    &__continue {
        text-align: center;;
    }

    &__back {
        color: var(--white);
    }
    
    &__subtitle {
        display: none;
    }

    &__profile-garage {
        text-align: center;
    }

    &__save {
        margin-top: 2rem;
        text-align: center;
    }

    &__addcar {
        margin-top: 1rem;
        text-align: center;
    }

    &__savecar {
        margin-top: 1rem;
        text-align: center;
    }

    &__deletecar {
        margin-top: 1rem;
        text-align: center;
    }


    &__profile-garage-car {
        .card {
            background-color: var(--light-gray);
            border-radius: 1rem;
            border: .5rem solid transparent;

            .profile__car-card-name {
                text-align: center;
                font-weight: bold;
            }
        }

        &--default {
            .card {
                border-color: var(--accent-color);
            }
        }
        .profile__profile-garage-picture {
            width: 100%;
            display: inline-block;
            position: relative;
            .profile__profile-garage-dummy {
                margin-top: 75%;
            }
            .profile__profile-garage-image {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 1rem;

                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
            .profile__profile-garage-icon {
                position: absolute;
                top: 1rem;
                right: 1rem;
                width: 1rem;
                height: 1rem;
                border-bottom: .25rem solid transparent;

                &:hover {
                    border-color: var(--accent-color);
                }
            }
        }
        &:hover {
            cursor: pointer;
        }
    }

    &__save--first {
        display: block;
    }
    &__save--second {
        display: none;
    }

    &__confirm {
        width: calc(100% - 2rem);
        margin: 1rem auto;
    }

    &__debug {
        font-weight: bold;
        color: var(--white);
        font-size: 1.5rem;
        margin: 1rem 0;
        text-align: center;
    }

    @media only screen and (max-width: 599px) {
        &__save--first {
            display: none;
        }
        &__save--second {
            display: block;
        }

        &__content--Garage {
            .profile__profile-info {
                display: none;
            }
        }
        &__content--Info {
            .profile__profile-garage {
                display: none;
            }
        }
        .profile__profile-garage-picture .profile__profile-garage-icon {

            width: 1.5rem;
            height: 1.5rem;
        }
    }



    @media only screen and (min-width: 600px) {
        &__layout {
            display: flex;
        }
        &__content {
            width: 100%;
            display: flex;
            padding: 3rem;
        }
        &__left {
            width: 50%;
            padding: 1rem;
        }
        &__right {
            width: 50%;
            padding: 1rem;
        }
        &__title {
            display: none;
        }
        &__subtitle {
            display: block;
        }
        .user-card {
            margin: 0;
        }
        .toggle-button {
            display: none;
        }
        .profile__profile-garage-picture .profile__profile-garage-icon {

            width: 1rem;
            height: 1rem;
        }
    }

    @media only screen and (min-width: 768px) {
        &__left {
            width: 75%;
        }
        &__right {
            width: 25%;
        }

        &__confirm {
            width: 50%;
            margin: 0 auto;
        }
    }

    @media only screen and (min-width: 1024px) {
        &__left {
            padding: 2rem;
        }
        &__right {
            padding: 2rem;
        }
        .profile__profile-garage-picture .profile__profile-garage-icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}