.eval {
    @media only screen and (min-width: 1280px) {
        margin: 0 auto;
        width: 1280px;
        position: relative;
    }

    &__header {
        @media only screen and (min-width: 1280px) {
            width: 350px;
            position: absolute;
            left: 0;
        }
    }
    &__student {
        display: flex;
        justify-content: space-evenly;

        @media only screen and (min-width: 1280px) {
            display: block;
        }

        &-card {
            background-color: var(--accent-color);
            border-radius: 1rem;
            padding: 1rem 2rem 2rem;
            text-align: center;
            position: relative;
            max-width: 10rem;
            margin: .5rem;
            max-width: 6rem;

            @media only screen and (min-width: 1280px) {
                margin: .5rem auto;
            }
        }

        &-contact {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; 
            justify-content: space-evenly;
            margin: .5rem;
            @media only screen and (min-width: 768px) {
                flex-direction: row;
                
            }
            @media only screen and (min-width: 1280px) {
                display: block;
            }

        }

        &-phone, &-email {
            background-color: var(--accent-color);
            font-size: .75rem;
            padding: .5rem 1rem;
            border-radius: .5rem;

            @media only screen and (min-width: 768px) {
                margin: 1rem;
            }
            @media only screen and (min-width: 1280px) {
                background-color: initial;
                margin: 0;
            }
            a {
                text-decoration: none;
                width: 3rem;
                @media only screen and (min-width: 768px) {
                    width: auto;
                }
            }

            img {
                margin: .5rem auto;
                display: block;
                @media only screen and (min-width: 1280px) {
                    display: inline;
                    width: 2rem;
                    height: 1rem;
                    position: relative;
                    top: .5rem;
                    right: .5rem;
                }
            }
            span {
                display: none;
                @media only screen and (min-width: 768px) {
                    display: inline;
                    position: relative;
                    top: -.2rem;
                }
            }

        }

        
        &-picture {
            margin: 0 auto;
            border-radius: 100%;
            overflow: hidden;
            width: 100px;
            height: 100px;
            .profile-picture {
                margin: 0;
                height: 100px;
                width: 100px;
                object-fit: fill;
                clip-path: circle(100px at center);
            }
        }
        &-name {
            font-size: 1.5rem;
        }
        &-car {
            font-size: .75rem;
        }
        &-card-footer {
            font-size: .75rem;
            padding: .25rem;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0 0 1rem 1rem;
            background-color: var(--success-color);
        }
    }

    &-content {
        position: relative;
        @media only screen and (min-width: 1280px) {
            margin-left: 350px;
        }
        &__back {
            margin-left: 1rem;
            @media only screen and (min-width: 768px) {
                position: absolute;
                left: 1rem;
                top: 1.5rem;
            }

            background-color: transparent;
            color: white;
            border: none;
            vertical-align: middle;
            img {
                color: white;
                width: 3rem;
                height: 3rem;
            }

            cursor: pointer;
        }
        &__title {
            margin-top: 1rem;
            text-align: center;
        }
        &__description {
            margin: .5rem 2rem;
        }
        &__continue {
            margin: 1rem;
            text-align: center;
        }
    
        &__inputs {
            display: flex;
            flex-wrap: wrap;
            > .eval-input {
                width: 100%;
                @media only screen and (min-width: 768px) {
                    width: 50%;
                }
            }
        }

        &__notes {
            display: block;
            margin: 1rem;
            width: 100%;
            label {
                display: block;
            }
            textarea {
                display: block;
                width: 100%;
                height: 5rem;
                margin: .5rem 0;
                font-size: 1rem;
                border-radius: .5rem;
            }
        }

        &__summaries {
            @media only screen and (min-width: 768px) {
                display: flex;
                flex-wrap: wrap;

                .eval-summary {
                    width: 50%;
                    &--wide {
                        width: 100%;
                    }
                }
            } 
        }

        &__message {
            text-align: center;
        }
    }
}