.instructor-eval-card {

    padding: 1rem;
    background-color: var(--accent-color);
    color: var(--white);
    border: .25rem solid var(--accent-color);
    margin: 1rem;
    border-radius: .5rem;
    font-size: 1.2rem;
    text-decoration: none;

    > div {
        
        padding: .25rem;
        text-align: center;
    }

    &__score {
        font-weight: bold;
        font-size: 1.4rem;
    }

    &:hover {
        color: var(--white);
        border: .25rem solid var(--white);
    }
}