.steps {
    display: flex;
    align-items: center; 
    justify-content: space-evenly;
    margin: 2rem;

    @media only screen and (min-width: 1280px) {
        justify-content: center;
    }

    &__step {
        width: 1rem;
        height: 1rem;
        @media only screen and (min-width: 1280px) {
            margin: .5rem 1rem;
        }
        background-color: white;
        border: .2rem solid white;
        border-radius: 1rem;
        &--active {
            background-color: var(--accent-color);
        }
    }
}