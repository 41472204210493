.progress-bar {
    color: var(--black);
    background-color: var(--light);
    padding: .25rem 2rem .5rem;
    border-radius: .25rem;
    
    &__labels {
        display: flex;
        justify-content:space-between;
        margin: .25rem;
    }
    
    &__label-top {
        color: var(--black);
    }
    &__label-bottom {
        color: var(--dark-gray);
    }
    
    &__graph {
        height: .5rem;
        background-color: var(--light-gray);
        border-radius: .25rem;
    }
    &__graph-inner {
        height: .5rem;
        background-color: var(--accent-color);
        border-radius: .25rem;
    }
}
