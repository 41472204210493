.modal {

    margin: 0 auto;

    width: 50%;
    max-height: 75%;
    overflow-y: auto;
    border-radius: 1rem;
    padding: 1rem;

    overflow-wrap: anywhere;

    background-color: var(--darker-gray);
    border: .5rem solid var(--accent-color);
    color: var(--white);

    &__background {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba(0, 0, 0, .9);
        z-index: 3;
    }


    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        .button {
            margin: 1rem 0 0;
        }
    }





}