.student-card {
    border-radius: 1rem;
    margin: 1rem .5rem;
    padding: 1rem 1rem 2rem;
    background-color: var(--background-color);
    color: var(--text-color);
    position: relative;
    text-align: center;

    &__picture {
        margin: 0 auto;
        border-radius: 100%;
        overflow: hidden;
        width: 75px;
        height: 75px;
        .profile-picture {
            margin: 0;
            height: 75px;
            width: 75px;
            object-fit: fill;
            clip-path: circle(75px at center);
        }
        margin-bottom: .5rem;
    }
    &__student {
        font-size: 1.5rem;
    }
    &__teacher {
        font-style: italic;
        font-size: .5rem;
    }
    &__evaluating {
        font-style: italic;
        font-size: .75rem;
        margin: .25rem;
    }
    &__car {
        font-size: .75rem;
    }
    &__card-footer {
        font-size: .75rem;
        padding: .25rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0 0 1rem 1rem;
        
        background-color: var(--success-color);
        &--Complete {
            background-color: var(--success-color);
        }
        &--Claimed {
            background-color: var(--warning-color);
        }
        &--Sent {
            background-color: var(--error-color);
        }
    }
}