
.admin-page {
    @media only screen and (min-width: 1280px) {
        margin: 0 auto;
        width: 1280px;
        position: relative;
    }

    .card {
        margin: 3rem 1rem;
    }

    &__title {
        color: var(--white);
        text-align: center;
    }
    
    &__back {
        color: var(--white);
    }

    &__card-title {
        color: var(--white);
    }

    &__commands {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        > * {
            margin: .5rem 1rem;
        }

        @media only screen and (min-width: 1280px) {
            flex-direction: row;
        }
    }
    
    .loader {
        margin: 0 auto;
    }

    &__event-image {
        width: 100px;
    }

    &__form {
        display: flex;
        flex-flow: wrap;
    }

    &__field {
        flex: 0 0 100%;
        @media only screen and (min-width: 768px) {
            flex: 0 0 50%;
        }
        margin-top: 1rem;
    }

    &__field-radio {
        flex: 0 0 50%;
        margin-top: 1rem;
    }

    &__label {
        width: calc(100% - 1rem);
        margin-left: .5rem;
        display: block;

        font-size: 1.5rem;
        margin: .5rem .25rem;
    }

    &__label-radio {
        margin-left: .5rem;
        font-size: 1.5rem;
        margin: .5rem .25rem;
    }

    &__input {
        width: calc(100% - 5rem);
        margin: .5rem .25rem;
        display: block;
        color: var(--dark-gray);
        font-size: 1.5rem;
        border-radius: .5rem;
        padding: .5rem 2rem;
        border: none;
        background-color: var(--light-gray);
    }

    &__input-radio {
        margin: .5rem .25rem;
        color: var(--dark-gray);
        font-size: 1.5rem;
        border-radius: .5rem;
        padding: .5rem 2rem;
        border: none;
        background-color: var(--light-gray);
    }

    &__save {
        width: 100%;

        button {
            display: block;
            margin: 1rem auto;
        }
    }

    &__attendees {
        display: flex;
        flex-wrap: wrap;
    }

    &__attendee {
        position: relative;
        width: 100%;
        margin-bottom: 2rem;

        @media only screen and (min-width: 768px) {
            width: 50%;
        }

        .card {        
            margin: 1rem 1rem 0;
            position: relative;
            padding-left: 75px;
        }

        &-info {
            padding: 0 1rem;
        }

        &-picture {
            position: absolute;
            left: 12.5px;
            top: 50%;
            transform: translateY(-50%);
            width: 50px;
            height: 50px;
            
            img {
                width: 100%;
                height: 100%;
                clip-path: circle(25px at center);
                object-fit: cover;
            }
        }
        &-options {
            display: flex;
        }
        &-option {
            width: 100%;
            padding: 0 1rem;
        }
        &-options-select {
            
            width: 100%;
            padding: .25rem;
            border-radius: .25rem;
            
            color: var(--dark-gray);
            background-color: var(--light-gray);
        }
        &-options-label {
            color: var(--white);
        }
    }

    &__slide {
        display: flex;
        overflow-x: auto;
        padding: 1rem 3rem 1rem 1rem;

        .student-card {
            margin: 0 1rem;
            a {
                text-decoration: none;
                &:hover {
                    color: var(--white);
                    text-decoration: underline;
                }
            }
        }
    }

    &__search {
        margin: 1rem 0;
        input {
            margin: 0 auto;
            width: 50%;
            display: block;
            font-size: 1.5rem;
            text-align: center;
        }
    }
    &__users {
        display: flex;
        overflow-x: auto;

        .user-card {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__info {
        color: var(--white);
        text-align: center;
        margin: 1rem 0;
    }

    &__history {
        text-align: center;
    }

    &__evals {
        display: flex;
        overflow-x: auto;
        padding: 1rem 3rem 1rem 1rem;
        justify-items: center;
    }

    &__history-eval {
        margin: .25rem 1rem;
        padding: .5rem 1.5rem;
        background-color: var(--accent-color);
        color: var(--white);
        border-radius: .5rem;
        text-decoration: none;
        
        &:hover {
            cursor: pointer;
            color: var(--white);
        }
        > div {
            padding: .2rem 0;
        }
    }
    &__history-eval-score {
        font-weight: bold;
    }
}

.admin-page__search-history-eval {

    border: none;

    margin: .25rem 1rem;
    padding: .5rem 1.5rem;
    background-color: var(--accent-color);
    color: var(--white);
    border-radius: .5rem;
    text-decoration: none;
    
    &:hover {
        cursor: pointer;
        color: var(--white);
    }
    > div {
        padding: .2rem 0;
    }
}

.admin-page__searchuserCard {
   
    &--selected {
        .user-card {
            border: 2px solid var(--white);
        }
    }

    .user-card {
        border: 2px solid var(--black);
        padding-bottom: 1rem;
        margin-bottom: 0;
    }
}

.admin-page__admin-card {
    display: flex;
    flex-flow: column;
    text-align: center;
}

.admin-page__admin-edit {
    color: var(--white);
    margin-bottom: 1rem;
}

.admin-page__searchusers {
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;

}

.search-back {
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    color: var(--white);
}

.admin-page__back {
    color: white;
}