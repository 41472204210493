.navigation {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    
    -webkit-user-select: none;
    user-select: none;

    &__hamburger {
        position: absolute;
        top: 25px;
        right: 25px;


        input {
            z-index: 3;
            position: absolute;
            width: 50px;
            height: 50px;
            top: -15px;
            left: -10px;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
        }

        span {
            display: block;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            position: relative;

            background: var(--light);
            border-radius: 3px;

            z-index: 1;

            transform-origin: 4px 0px;

            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            opacity 0.55s ease;
        }

        span:first-child {
            transform-origin: 0% 50%;
        }

        span:nth-last-child(2) {
            transform-origin: 50% 100%;
        }

        span:nth-last-child(1) {
            transform-origin: 75% 0%;
        }

        &--open {
            span {
                opacity: 1;
                transform: rotate(45deg) translate(-2px, -1px);
            }

            span:nth-last-child(2) {
                transform: rotate(-45deg) translate(0, -1px);
            }

            span:nth-last-child(3) {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }
        }
    }




    &__menu {
        margin: 0;
        padding: 0;
        padding-top: 125px;
        
        text-align: right;
        height: 100%;
        background: var(--darker-gray);

        
        transform-origin: 0% 0%;
        transform: translate(200%, 0);
        
        transition: all 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        padding-left: 0;
        width: 0;
        &--open {
            width: auto;
            transform: none;
            padding-left: 188px;
            @media only screen and (max-width: 768px) {
                width: 100vw;
            }
        }

        @media only screen and (max-width: 768px) {
            padding-left: 0;
            height: auto;
        }

        h1 {
            color: var(--white);
            position: absolute;
            top: 0;
            left: 25px;
        }
    }

    &__list {
            list-style-type: none;
            margin: 0;
            padding: 1rem 0;
    }

    &__link {
        color: var(--light);
        font-size: 1.5rem;
        margin: 0 1rem;
        padding: 1rem;
        a {
            text-decoration: none;
        }
    }

}