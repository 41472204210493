.eval-summary {

    &__label {
        text-align: center;
        font-weight: bold;
        font-size: 1.25rem;
        img {
            margin-left: .5rem;
        }
    }

    &__properties {
        padding: .5rem;
    }

    &__graph {
        color: var(--black);
        background-color: var(--light);
        padding: .25rem 2rem .5rem;
        border-radius: 1.5rem;
    }

    &__graph-labels {
        display: flex;
        justify-content:space-between;
        margin: .25rem;
    }

    &__graph-label {
        color: var(--black);
    }
    &__graph-average {
        color: var(--dark-gray);
    }

    &__graph-outer {
        height: .5rem;
        background-color: var(--light-gray);
        border-radius: .25rem;
    }
    &__graph-inner {
        height: .5rem;
        background-color: var(--accent-color);
        border-radius: .25rem;
    }
}