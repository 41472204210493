.button, a.button {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 2.5rem;
    
    background-color: var(--white);
    color: var(--accent-color);
    border: var(--border-width) solid var(--accent-color);
    font-weight: bold;

    &--active {
        cursor: pointer;
        &:hover {
            border: var(--border-width) solid var(--white);
        }
        &:active {
            background-color: var(--accent-color);
            color: var(--white);
            border: var(--border-width) solid var(--accent-color);
        }
    }

    &--disabled {
        cursor:not-allowed;
        background-color: var(--light-gray);
        color: var(--dark-gray);
        border: var(--border-width) solid var(--light-gray);
    }

    &--admin {
        text-transform: uppercase;
        font-size: 1rem;
        padding: .5rem 1rem;
        border-radius: .25rem;
        text-decoration: none;
        
        background-color: var(--white);
        color: var(--accent-color);
        border: var(--border-width) solid var(--accent-color);
        font-weight: bold;

        background-color: var(--black);
        color: var(--white);
        border: var(--border-width) solid var(--black);
        font-weight: bold;

        text-align: center;
    
        &:hover {
            color: var(--white) !important;
            border: var(--border-width) solid var(--white);
        }
        &:active {
            background-color: var(--accent-color);
            color: var(--white);
            border: var(--border-width) solid var(--accent-color);
        }
    }
}