
.student-dashboard {

    padding: 2rem;

    &__title {
        color: var(--white);
        text-align: center;
    }

    &__subtitle {
        color: var(--white);
    } 
    
    &__back {
        color: var(--white);
    }

    &__search {
        padding: 0.5rem 1rem;
        width: 75%;
        max-width: 100%;
        border-radius: 0.5rem;
        color: var(--background-color);
        background-color: var(--light-gray);
        font-weight: bold;
    }

    &__slide {
        display: flex;
        overflow-x: auto;
        padding: 1rem 3rem 1rem 1rem;

        .student-card {
            margin: 0 1rem;
            a {
                text-decoration: none;
                &:hover {
                    color: var(--white);
                    text-decoration: underline;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        &__layout {
            display: flex;
    
            .user-card {
                width: 25%;
                align-self: flex-start;
            }
        }
        &__content {
            width: 75%;
        }   
    }

    &__charts {
        h2 {
            text-align: center;
            text-transform: uppercase;
        }

        .card {
            background-color: var(--chart-background);
        }


        @media only screen and (min-width: 1024px) {
            display: flex;
            > .student-dashboard__wasp, > .card {
                width: 50%;
            }
        }    

    }

    &__apex {
        width: 100%;
        height: 350px;

    }

    .student-progress-chart {
        height: calc(100% - 4rem);
    }
}

.student-progress {

}