.toggle-button {

    border-radius: .5rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--darker-gray);
    border-radius: 1rem;
    width: fit-content;
    padding: .25rem 1rem ;

    &__option {
        font-size: 1rem;
        margin: 0 .5rem;
        padding: .5rem 2rem;
        text-decoration: none;

        border-radius: .5rem;
    
        background-color: var(--darker-gray);
        color: var(--white);
        font-weight: bold;
    
        text-align: center;
        cursor: pointer;

        border: none;

        &:hover {
            background-color: var(--accent-color);
        }
        &:active {
            background-color: var(--accent-color);
        }
    
        &--selected {
            background-color: var(--dark-gray);
            &:hover {
                background-color: var(--accent-color);
            }
            &:active {
                background-color: var(--accent-color);
                color: var(--white);
            }
        }
    }

}