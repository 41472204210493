.image-uploader {
    &__input {
        input {
            display: none;
        }
        label {
            color: var(--white);
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}