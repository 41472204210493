
.common-eval-page {
    &__title {
        color: white;
        text-align: center;
    }

    &__back {
        color: white;
    }

    &__summaries {
        display: flex;
        flex-wrap: wrap;
    }

    .eval-summary {
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: 50%;
        }
    }
    .eval-summary--wide {
        width: 100%;
    }
    &__history {
        text-align: center;
    }

    &__history-container {
        display: flex;
        overflow-x: auto;
        padding: 1rem 3rem 1rem 1rem;
        justify-items: center;
    }

    &__history-eval {
        margin: .25rem 1rem;
        padding: .5rem 1.5rem;
        background-color: var(--accent-color);
        border-radius: .5rem;
        text-decoration: none;
        
        &:hover {
            color: var(--white);
            background-color: var(--accent-color);
            text-decoration: underline;
        }
        > div {
            padding: .2rem 0;
        }
    }
    &__history-eval-score {
        font-weight: bold;
    }
    &__extra-info {
        @media only screen and (max-width: 1279px) {
            text-align: center;
        }
        
    }
}


.common-eval-page {
    @media only screen and (min-width: 1280px) {
        margin: 0 auto;
        width: 1280px;
        position: relative;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        @media only screen and (min-width: 1280px) {
            flex-wrap: nowrap;
        }
    }

    &__profile {
        width: 100%;
        @media only screen and (min-width: 1280px) {
            width: 350px;
        }
    }

    &__student {
        display: flex;
        justify-content: space-evenly;

        @media only screen and (min-width: 1280px) {
            display: block;
        }

        .student-card {
            background-color: var(--accent-color);
        }

        &-contact {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; 
            justify-content: space-evenly;
            margin: .5rem;
            @media only screen and (min-width: 768px) {
                flex-direction: row;
            }
            @media only screen and (min-width: 1280px) {
                display: block;
            }

        }

        &-phone, &-email {
            background-color: var(--accent-color);
            font-size: .75rem;
            padding: .5rem 1rem;
            border-radius: .5rem;

            @media only screen and (min-width: 768px) {
                margin: 1rem;
            }
            @media only screen and (min-width: 1280px) {
                background-color: initial;
                margin: 0;
            }
            a {
                text-decoration: none;
                width: 3rem;
                @media only screen and (min-width: 768px) {
                    width: auto;
                }
            }

            img {
                margin: .5rem auto;
                display: block;
                @media only screen and (min-width: 1280px) {
                    display: inline;
                    width: 2rem;
                    height: 1rem;
                    position: relative;
                    top: .5rem;
                    right: .5rem;
                }
            }
            span {
                display: none;
                @media only screen and (min-width: 768px) {
                    display: inline;
                    position: relative;
                    top: -.2rem;
                }
            }

        }

        
        &-picture {
            margin: 0 auto;
            border-radius: 100%;
            overflow: hidden;
            width: 100px;
            height: 100px;
            .profile-picture {
                margin: 0;
                height: 100px;
                width: 100px;
                object-fit: fill;
                clip-path: circle(100px at center);
            }
        }
        &-name {
            font-size: 1.5rem;
        }
        &-car {
            font-size: .75rem;
        }
        &-card-footer {
            font-size: .75rem;
            padding: .25rem;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0 0 1rem 1rem;
            background-color: var(--success-color);
        }
    }

    &__content {
        position: relative;
        width: 100%;
        @media only screen and (min-width: 1280px) {
            
        }
        &__back {
            margin-left: 1rem;
            @media only screen and (min-width: 768px) {
                position: absolute;
                left: 1rem;
                top: 1.5rem;
            }

            background-color: transparent;
            color: white;
            border: none;
            vertical-align: middle;
            img {
                color: white;
                width: 3rem;
                height: 3rem;
            }

            cursor: pointer;
        }
        &__title {
            margin-top: 1rem;
            text-align: center;
        }
        &__description {
            margin: .5rem 2rem;
        }
        &__continue {
            margin: 1rem;
            text-align: center;
        }
    
        &__inputs {
            display: flex;
            flex-wrap: wrap;
            > .eval-input {
                width: 100%;
                @media only screen and (min-width: 768px) {
                    width: 50%;
                }
            }
        }


        &__summaries {
            @media only screen and (min-width: 768px) {
                display: flex;
                flex-wrap: wrap;

                .eval-summary {
                    width: 50%;
                }
            } 
        }

        &__message {
            text-align: center;
        }
    }
}