.loader {
    margin: 0 auto;
    border: 16px solid var(--text-color);
    border-top: 16px solid var(--accent-color);
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}
  
