
html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.background {
    height: 100%;
    padding: 0;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    &__image-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        overflow: hidden;

        z-index: -1;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(180deg, rgba(0,0,0,.5), rgba(0,0,0,1));
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content-container {
        padding: 0;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        z-index: 1;
    }
}