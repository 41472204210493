:root {
    --background-color: rgba(75,75,75,1);
    --text-color: rgba(250,250,250,1);
    --accent-color: rgb(154, 2, 2);

    --success-color: rgb(0, 141, 38);
    --warning-color: rgb(145, 142, 6);
    --error-color: #9A0202;

    --gold: #ffbb00;

    --border-width: .5rem;

    --accent-color: #9A0202;
    --accent-link-color: #ff0e0e;
    
    --white: #FFFFFF;
    --light: #EEEEEE;
    --lighter-gray: rgb(112, 112, 112);
    --light-gray: #AAAAAA;
    
    --dark-gray: #585858;
    --darker-gray: #2b2b2b;


    
    --transparent-dark-grey: rgba(75, 75, 75, .85);
    --dark: #333333;
    --black: #000000;


    --chart-line: #707070;
    --chart-background: #7C7C7C;
    --chart-bar: #9A0202;
}